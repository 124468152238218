<template>
  <!--begin::as-table-->
  <div class="as-table pt-6">
    <v-data-table
        :headers="fields"
        :items="asItems"
        class="elevation-1 farm-data-table"
        :no-data-text="`${i18n.noData}`"
        hide-default-footer
    >
      <template v-slot:[`item.fieldC`]="{ item }">
        <a href="javascript:;" class="text-dark">{{ item.fieldC }}</a>
      </template>
      <template v-slot:[`item.fieldF`]="{ item }">
        <b-button v-if="item.claimStDcd === '1016001' && myMno === item.targetMno" size="sm" variant="outline-dark" class="rounded-sm mr-2" @click="editAs(item.boardNo)">
          <span class="flaticon-edit font-size-xs mr-1"></span>
          <span class="text font-weight-normal">{{i18n.edit}}</span>
        </b-button>

        <b-button size="sm" variant="dark" class="rounded-sm" @click="viewAs(item.boardNo)">
          <span class="flaticon-eye font-size-xs mr-1"></span>
          <span class="text font-weight-normal">{{i18n.look}}</span>
        </b-button>
      </template>
    </v-data-table>
    <!--begin::pagination-->
    <div v-if="paging && paging.totalCount" class="pagination mt-12">
      <b-pagination-nav
        :number-of-pages="totalPage"
        :link-gen="linkGen"
        use-router
      >
        <template #first-text><i class="fas fa-angle-double-left"></i></template>
        <template #prev-text><i class="fas fa-angle-left"></i></template>
        <template #next-text><i class="fas fa-angle-right"></i></template>
        <template #last-text><i class="fas fa-angle-double-right"></i></template> 
        <template #page="{ page, active }">
          <span v-if="active" class="font-size-sm font-weight-bold text-white">{{ page }}</span>
          <span v-else class="font-size-sm font-weight-bold text-dark">{{ page }}</span>
        </template>
      </b-pagination-nav>
    </div>
    <!--end::pagination-->
  </div>
  <!--end::as-table-->
</template>

<script>
import {pagingSequence, timestampToDate} from '@/core/services/funcs';
import {mapGetters} from 'vuex';
import i18n from '@/core/plugins/vue-i18n.js';

export default {
  name: "AsList",
  props: {
    items: Array,
    paging: Object,
    claimStDcd: String
  },
  computed: {
    ...mapGetters(['myMno']),
    asItems() {
      const items = [];
      this.items.forEach((item, idx) => {
        items.push({
          'idx': pagingSequence(this.paging, idx), 'fieldA' : item.memberNm, 'fieldB' : item.claimType, 'fieldC' : item.title, 'fieldD' : timestampToDate(item.createDt), 'fieldE' : item.claimSt,
          'claimStDcd' : item.claimStDcd, 'targetMno': item.targetMno,
          'boardNo': item.boardNo
        });
      });
      return items;
    },
    totalPage() {
      if(this.paging){
        return Math.ceil(this.paging.totalCount / this.paging.pageSize);
      }
      return 0;
    },
  },
  data() {
    return {
      fields: [
        { value: 'idx', text: i18n.t('claim.numbering'), width: '80px' },
        { value: 'fieldA', text: i18n.t('claim.registrant') },
        { value: 'fieldB', text: i18n.t('claim.type') },
        { value: 'fieldC', text: i18n.t('claim.claim') },
        { value: 'fieldD', text: i18n.t('claim.registeredDate') },
        { value: 'fieldE', text: i18n.t('claim.progressStatus') },
        { value: 'fieldF', text: '', width: '200px' }
      ],
      i18n:{
        look: i18n.t('claim.look'),
        edit: i18n.t('claim.edit'),
        noData: i18n.t('claim.noData'),
      }


    }
  },
  methods: {
    linkGen(pageNum) {
      if(pageNum === 1){
        if(this.claimStDcd) return `?claimStDcd=${this.claimStDcd}`;
        else return '?'
      }else{
        if(this.claimStDcd) return `?page=${pageNum}&claimStDcd=${this.claimStDcd}`
        else return `?page=${pageNum}`
      }
    },
    viewAs(boardNo){
      this.$router.push('as-view/' + boardNo);
    },
    editAs(boardNo){
      this.$router.push('as/' + boardNo);
    }

  }
};
</script>
