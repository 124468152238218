var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"as-table pt-6"},[_c('v-data-table',{staticClass:"elevation-1 farm-data-table",attrs:{"headers":_vm.fields,"items":_vm.asItems,"no-data-text":("" + (_vm.i18n.noData)),"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.fieldC",fn:function(ref){
var item = ref.item;
return [_c('a',{staticClass:"text-dark",attrs:{"href":"javascript:;"}},[_vm._v(_vm._s(item.fieldC))])]}},{key:"item.fieldF",fn:function(ref){
var item = ref.item;
return [(item.claimStDcd === '1016001' && _vm.myMno === item.targetMno)?_c('b-button',{staticClass:"rounded-sm mr-2",attrs:{"size":"sm","variant":"outline-dark"},on:{"click":function($event){return _vm.editAs(item.boardNo)}}},[_c('span',{staticClass:"flaticon-edit font-size-xs mr-1"}),_c('span',{staticClass:"text font-weight-normal"},[_vm._v(_vm._s(_vm.i18n.edit))])]):_vm._e(),_c('b-button',{staticClass:"rounded-sm",attrs:{"size":"sm","variant":"dark"},on:{"click":function($event){return _vm.viewAs(item.boardNo)}}},[_c('span',{staticClass:"flaticon-eye font-size-xs mr-1"}),_c('span',{staticClass:"text font-weight-normal"},[_vm._v(_vm._s(_vm.i18n.look))])])]}}],null,true)}),(_vm.paging && _vm.paging.totalCount)?_c('div',{staticClass:"pagination mt-12"},[_c('b-pagination-nav',{attrs:{"number-of-pages":_vm.totalPage,"link-gen":_vm.linkGen,"use-router":""},scopedSlots:_vm._u([{key:"first-text",fn:function(){return [_c('i',{staticClass:"fas fa-angle-double-left"})]},proxy:true},{key:"prev-text",fn:function(){return [_c('i',{staticClass:"fas fa-angle-left"})]},proxy:true},{key:"next-text",fn:function(){return [_c('i',{staticClass:"fas fa-angle-right"})]},proxy:true},{key:"last-text",fn:function(){return [_c('i',{staticClass:"fas fa-angle-double-right"})]},proxy:true},{key:"page",fn:function(ref){
var page = ref.page;
var active = ref.active;
return [(active)?_c('span',{staticClass:"font-size-sm font-weight-bold text-white"},[_vm._v(_vm._s(page))]):_c('span',{staticClass:"font-size-sm font-weight-bold text-dark"},[_vm._v(_vm._s(page))])]}}],null,false,3725413418)})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }