
<template>
  <div class="page-as">
    <div class="card card-custom card-stretch gutter-b farm-crops-conatiner">
      <!--begin::Header-->
      <div class="card-header border-0">
        <t-farmon-table-header
            :no-blank="true"
        ></t-farmon-table-header>
        <div class="card-toolbar toolbar-symbol">
          <router-link
              :to="'/smartfarm/' + farmId +'/as/0'"
              v-slot="{ href, navigate }"
          >
            <b-button variant="dark" class="rounded-sm" size="sm" @click="navigate">{{i18n.register}}</b-button>
          </router-link>
        </div>
      </div>
      <!--end::Header-->
      <!--begin::Body-->
      <div class="card-body pt-4">
        <!--begin::as-tabs-->
        <div class="as-tabs">
          <b-tabs>
            <b-tab :active="!claimStDcd" @click="selectClaimSt('')">
              <template slot="title">
                <span class="nav-text font-weight-bold font-size-h6 font-size-h4-lg">{{i18n.all}} {{ !claimStDcd ? ' (' + paging.totalCount + ')' : '' }}</span>
              </template>
            </b-tab>
            <b-tab :active="claimStDcd === '1016001'" @click="selectClaimSt('1016001')">
              <template slot="title">
                <span class="nav-text font-weight-bold font-size-h6 font-size-h4-lg">{{i18n.requested}} {{ claimStDcd === '1016001' ? ' (' + paging.totalCount + ')' : '' }}</span>
              </template>
            </b-tab>
            <b-tab :active="claimStDcd === '1016002'" @click="selectClaimSt('1016002')">
              <template slot="title">
                <span class="nav-text font-weight-bold font-size-h6 font-size-h4-lg">{{i18n.inProgress}} {{ claimStDcd === '1016002' ? ' (' + paging.totalCount + ')' : '' }}</span>
              </template>
            </b-tab>
            <b-tab :active="claimStDcd === '1016003'" @click="selectClaimSt('1016003')">
              <template slot="title">
                <span class="nav-text font-weight-bold font-size-h6 font-size-h4-lg">{{i18n.completed}} {{ claimStDcd === '1016003' ? ' (' + paging.totalCount + ')' : '' }}</span>
              </template>
            </b-tab>
            <b-tab :active="claimStDcd === '1016004'" @click="selectClaimSt('1016004')">
              <template slot="title">
                <span class="nav-text font-weight-bold font-size-h6 font-size-h4-lg">{{i18n.confirmed}} {{ claimStDcd === '1016004' ? ' (' + paging.totalCount + ')' : '' }}</span>
              </template>
            </b-tab>
            <AsList
              :items="items"
              :paging="paging"
              :claim-st-dcd="claimStDcd"
            ></AsList>
          </b-tabs>
        </div>
        <!--end::as-tabs-->
      </div>
      <!--end::Body-->
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import AsList from "@/view/content/as/AsList.vue";
import {ACT_GET_MY_CLAIMS} from '@/core/services/variable';
import {lengthCheck, getItems, getPaging} from '@/core/services/funcs';
import TFarmonTableHeader from '@/components/TFarmonTableHeader';
import i18n from '@/core/plugins/vue-i18n.js';

export default {
  name: "As",
  components: {
    AsList,
    TFarmonTableHeader
  },
  beforeMount() {
    this.syncQueryParam(this.$route.query.page, this.$route.query.claimStDcd);
    this.getClaims();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "A/S", "icon": 'fas fa-smile-beam' }]);
    window.scrollTo(0,0);
  },
  watch: {
    '$route'(to){
      this.syncQueryParam(to.query.page, to.query.claimStDcd)
      this.getClaims();
    }
  },
  computed: {
    ...mapGetters(["farmId"])
  },
  data() {
    return {
      items: [],
      paging: {
        pageNo: 1,
        pageSize: 10,
        totalCount: 0
      },
      claimStDcd: '',
      i18n:{
        all: i18n.t('claim.all'),
        requested: i18n.t('claim.requested'),
        inProgress: i18n.t('claim.inProgress'),
        completed: i18n.t('claim.completed'),
        confirmed: i18n.t('claim.confirmed'),
        register: i18n.t('claim.register')
      }


    }
  },
  methods: {
    getClaims(){
      this.$store.dispatch(ACT_GET_MY_CLAIMS, {claimStDcd: this.claimStDcd, pageNo: this.paging.pageNo, pageSize: this.paging.pageSize}).then(resp => {
        if(lengthCheck(resp)){
          this.items = getItems(resp);
        }else{
          this.items = [];
        }
        this.paging = getPaging(resp);
      }).catch(e => {
        console.error(e);
        this.items = [];
        this.paging = getPaging();
      });
    },
    selectClaimSt(claimStDcd){
      if(!(this.claimStDcd === claimStDcd)){
        this.claimStDcd = claimStDcd
        this.pageNo = 1
        if(this.claimStDcd){
          this.$router.push({query: {claimStDcd: this.claimStDcd}});
        }else{
          this.$router.push({query: {}});
        }
      }
    },
    syncQueryParam(page, claimStDcd){
      if(page){
        this.paging.pageNo = page;
      }else {
        this.paging.pageNo = 1
      }
      if(claimStDcd){
        this.claimStDcd = claimStDcd;
      }else {
        this.claimStDcd = ''
      }
    }
  }
};
</script>
